.about-session-one {
  .img-fluid {
    height: 600px;
  }
  h3 {
    @include font-Montserrat-semibold;
    color: $blue;
  }
}
.about-session-two {
  .service-list {
    margin-left: 0 !important;
    padding-left: 0 !important;
    .service-link {
      appearance: none;
      list-style-type: none;
      p {
        display: flex;
        position: relative;
        text-decoration: none;
        svg {
          margin-left: 0 !important;
          padding-right: $padding-step-2;
          padding-top: 2px;
        }
      }
    }
  }
}
.about-timeline {
  width: 90%;
  // padding-top: $padding-step-2;
  background-color: $white;
}
.team {
  .team-grids {
    position: relative;
    overflow: hidden;
    z-index: 1;
    img {
      border-radius: 8px;
      width: 200px;
    }
  }
  h4 {
    text-transform: capitalize;
    margin-top: 20px;
  }
  h6 {
    color: $black;
  }
}
.with-bg {
  background-image: url("../../public/image/about.png");
  background-position: 20% 50%;
  background-repeat: no-repeat;
  height: 42rem;
  background-size: contain;
  position: relative;
}
.text-container {
  // position: absolute;
  // width: 50rem;
  // border: 1px solid #ebbabe;
  // padding: 16px;
  // border-radius: 8px;
  // background: linear-gradient(90deg, hsla(0, 0, 100%, 0.67), $white);
  // right: 130px;
  // top: 25%;
  @include bp(md-max) {
    // width: auto;
    // margin: 0 $margin-step-1;
    // left: 0;
    // right: 0;
  }
}

// For timeline

$color-white: #fff;
$brand-color-g: #348e80;
$brand-color: #df8625;
$blue: #169faf;

.ps-timeline-sec {
  position: relative;
  background: $color-white;

  .timeline-container {
    position: relative;
    width: 100%;
    ol {
      
      p{
        font-size: 14px !important;
        line-height: 18px !important;
      }
      @include bp(md-max) {
        .ps-bot,
        .ps-top {
          border: none !important;
          margin-top: 0px !important;
        }
        .ps-sp-top,
        .ps-sp-bot {
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            left: 13% !important;
            top: 15% !important;
          }
        }
        &:before {
          background: $brand-color-g;
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 100%;
          position: absolute;
          top: 0px !important;
          left: 22px !important;
        }

        &:after {
          background: $brand-color-g;
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 100%;
          position: absolute;
          top: inherit !important;
          left: 22px;
        }

        &.ps-timeline {
          margin: 130px 0 !important;
          border-left: 4px solid $brand-color-g;
          padding-left: 0 !important;
          padding-top: 120px !important;
          border-top: 0 !important;
          margin-left: 25px !important;

          li {
            height: 220px;
            float: none !important;
            width: inherit !important;
            display: block !important;

            &:nth-child(2) {
              .img-handler-bot {
                margin-top: -14px !important;
                img {
                  width: 70px;
                }
              }
            }

            &:last-child {
              margin: 0;
              bottom: 0 !important;
              height: 120px;

              .img-handler-bot {
                // bottom: 40px !important;
                // // width: 40% !important;
                // margin-left: 25px !important;
                // margin-top: -14px !important;

                img {
                  width: 100%;
                }
              }

              .ps-top {
                margin-bottom: 0 !important;
                top: 20px;
                // width: 50% !important;
              }
            }
            span {
              left: 0 !important;

              &.ps-sp-top {
                &:before {
                  content: none !important;
                }

                &:after {
                  content: none !important;
                }
              }

              &.ps-sp-bot {
                &:before {
                  content: none !important;
                }

                &:after {
                  content: none !important;
                }
              }
            }

            .img-handler-top {
              // position: absolute !important;
              top: 0px !important;
              width: auto !important;
              float: left !important;
              margin-left: 35px !important;
              margin-bottom: 0 !important;
              margin-top: -14px !important;

              img {
                margin: 0 auto !important;
                width: 80% !important;
              }
            }

            .img-handler-bot {
              // position: absolute !important;
              top: 0px !important;
              width: auto !important;
              float: left !important;
              margin-left: 35px !important;
              margin-bottom: 0 !important;
              margin-top: -14px !important;

              img {
              }
            }

            p {
              text-align: left !important;
              width: 100% !important;
              margin: 0 auto !important;
              margin-top: 0px !important;
            }

            .ps-top {
              // width: 75% !important;
              // float: right !important;
              // right: 0;
              // top: -40px;
              padding-left: 35px;
              bottom: unset !important;
            }

            .ps-bot {
              // width: 75% !important;
              // float: right !important;
              // right: 0;
              // top: -40px;
              padding-left: 35px;

              bottom: unset !important;
            }
          }
        }
      }

      &:before {
        // background: $brand-color-g;
        // content: "";
        // width: 10px;
        // height: 10px;
        // border-radius: 100%;
        // position: absolute;
        // left: 8px;
        // top: 49.5%;
      }

      &:after {
        // background: $brand-color-g;
        // content: "";
        // width: 10px;
        // height: 10px;
        // border-radius: 100%;
        // position: absolute;
        // right: 8px;
        // top: 49.5%;
      }

      &.ps-timeline {
        margin: 15.625rem 0rem;
        padding: 0;
        border-top: 4px solid $brand-color-g;
        list-style: none;

        li {
          float: left;
          width: 10%;
          padding-top: 30px;
          position: relative;

          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          // &:first-child{
          //   left: 20px;
          // }
          .icon {
            width: 40px;
          }
          span {
            width: 50px;
            height: 50px;
            margin-left: -25px;
            background: $color-white;
            border: 4px solid $brand-color-g;
            border-radius: 50%;
            box-shadow: 0 0 0 0px $color-white;
            text-align: center;
            line-height: 50px -10;
            color: $brand-color;
            font-size: 2em;
            font-style: normal;
            position: absolute;
            top: -26px;
            left: 50%;

            &.ps-sp-top {
              svg {
                display: block;
                width: 24px;
                height: 24px;
                position: relative;
                left: 50%;
                top: 50%;
                margin-left: -12px;
                margin-top: -12px;
              }
              &:before {
                content: "";
                color: $brand-color-g;
                width: 2px;
                height: 50px;
                background: $brand-color-g;
                position: absolute;
                top: -50px;
                left: 50%;
              }

              &:after {
                content: "";
                color: $brand-color-g;
                width: 8px;
                height: 8px;
                background: $brand-color-g;
                position: absolute;
                bottom: 92px;
                left: 44%;
                border-radius: 100%;
              }
            }

            &.ps-sp-bot {
              svg {
                display: block;
                width: 24px;
                height: 24px;
                position: relative;
                left: 50%;
                top: 50%;
                margin-left: -12px;
                margin-top: -12px;
              }
              &:before {
                content: "";
                color: $brand-color-g;
                width: 2px;
                height: 50px;
                background: $brand-color-g;
                position: absolute;
                left: 50%;
                top: 46px;
              }

              &:after {
                content: "";
                color: $brand-color-g;
                width: 8px;
                height: 8px;
                background: $brand-color-g;
                position: absolute;
                top: 92px;
                left: 43%;
                border-radius: 100%;
              }
            }
          }

          .img-handler-top {
            position: absolute;
            bottom: 0;
            margin-bottom: 130px;
            width: 100%;

            img,
            p {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: center;
              color: $brand-color-g;
              width: 100px;
              font-size: 24px;
              font-weight: 800;
            }
          }

          .img-handler-bot {
            position: absolute;
            margin-top: 60px;
            width: 100%;

            img,
            p {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: center;
              color: $brand-color-g;
              width: 100px;
              font-size: 24px;
              font-weight: 800;
            }
          }

          p {
            text-align: center;
            width: 80%;
            margin: 0 auto;
          }

          .ps-top {
            position: absolute;
            bottom: 0;
            margin-bottom: 100px;

            border: 2px solid $brand-color-g;
            border-radius: 8px;
            width: auto;

            p {
              margin: 16px;
            }
            &:after {
              content: "";
              color: $brand-color-g;
              width: 2px;
              height: 49px;
              background: $brand-color-g;
              position: absolute;
              bottom: -50px;
              left: 50%;
              @include bp(md-max){
                content: none;
              }
            }
          }

          .ps-bot {
            position: absolute;
            margin-top: 35px;

            border: 2px solid $brand-color-g;
            border-radius: 8px;
            width: auto;
            p {
              margin: 16px;
            }
            &:after {
              content: "";
              color: $brand-color-g;
              width: 2px;
              height: 49px;
              background: $brand-color-g;
              position: absolute;
              top: -50px;
              left: 50%;
              @include bp(md-max){
                content: none;
              }
            }
          }
        }
      }
    }
  }
}
