// colors
$black: #000;
$black-1: #060606;
$black-2: #040a17;

$white: #fff;
$white-op: #ffffffc2;
$white-op-0: #fafafa;
$white-op-1: #bababa;
$white-op-2: #f4f6f9;

$blue: #169faf;
$dark-blue: #060e1f;
$footer-dark-blue: #042c31;
$blue-op: #59afb9;
$blue-op-less: #169faf29;

$grey: #f4f4f4;

$overlay: #13191794;
$red: #ff0808;

// Padding
$padding-step-0: 0px;
$padding-step-1: 8px;
$padding-step-2: 16px;
$padding-step-3: 24px;
$padding-step-4: 32px;
$padding-step-5: 40px;
$padding-step-6: 48px;
$padding-step-7: 56px;
$padding-step-8: 64px;
$padding-step-9: 72px;
$padding-step-10: 80px;

// margin
$margin-step-0: 0px;
$margin-step-1: 8px;
$margin-step-2: 16px;
$margin-step-3: 24px;
$margin-step-4: 32px;
$margin-step-5: 40px;
$margin-step-6: 48px;
$margin-step-7: 56px;
$margin-step-8: 64px;
$margin-step-9: 72px;
$margin-step-10: 80px;

// Font size i.e  $font10 = 10px
$font12: 0.75rem;
$font14: 0.875rem;
$font15: 0.938rem;
$font16: 1rem;
$font18: 1.125rem;
$font20: 1.25rem;
$font22: 1.375rem;
$font25: 1.563rem;
$font30: 1.875rem;
$font35: 2.188rem;
$font38: 2.375rem;
$font50: 3.125rem;
$font56: 3.5rem;

// Font line height
$line-height-14: 14px;
$line-height-16: 16px;
$line-height-18: 18px;
$line-height-20: 20px;
$line-height-21: 21px;
$line-height-24: 24px;
$line-height-25: 25px;
$line-height-26: 26px;
$line-height-28: 28px;
$line-height-30: 30px;
$line-height-36: 36px;
$line-height-38: 38px;
$line-height-42: 42px;
$line-height-46: 46px;
$line-height-56: 46px;
$line-height-60: 60px;

// Fonts used

$font-family-Montserrat: "Montserrat", sans-serif;

@mixin font-Montserrat-regular {
  font-family: $font-family-Montserrat;
  font-style: normal;
  font-weight: 300;
}

@mixin font-Montserrat-medium {
  font-family: $font-family-Montserrat;
  font-style: normal;
  font-weight: 400;
}

@mixin font-Montserrat-semibold {
  font-family: $font-family-Montserrat;
  font-style: normal;
  font-weight: 600;
}

// Break Points
$screen-sm-min: 576px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

// break points
@mixin bp($point) {
  @if $point == xl-min {
    @media (min-width: $screen-xl-min) {
      @content;
    }
  } @else if $point == lg-max {
    @media (max-width: ($screen-xl-min - 1)) {
      @content;
    }
  } @else if $point == lg-min {
    @media (min-width: $screen-lg-min) {
      @content;
    }
  } @else if $point == md-max {
    @media (max-width: ($screen-md-max)) {
      @content;
    }
  } @else if $point == md-min {
    @media (min-width: ($screen-md-min)) {
      @content;
    }
  } @else if $point == sm-max {
    @media (max-width: ($screen-sm-max)) {
      @content;
    }
  } @else if $point == sm-min {
    @media (max-width: ($screen-sm-min)) {
      @content;
    }
  }
}
