.inner-banner {
  background: url(../../public/image/b1.jpeg) no-repeat top;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  z-index: 0;
  padding-bottom: 8em !important;
  color: $white !important;
  .inner-text-title {
    text-transform: capitalize;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
    background: var(--layer);
  }
  .breadcrumbs-custom-path {
    margin: 0;
    padding: 0;
    z-index: 99;
    position: absolute;
    a {
      padding-right: $padding-step-1;
      text-transform: capitalize;
      &::after {
        content: "\003E";
        // padding-left: $padding-step-1;
      }
      &:last-child {
        color: $white-op-1;
        pointer-events: none;
        &::after {
          content: " ";
          // padding-left: $padding-step-1;
        }
      }
    }
    span {
      padding-right: $padding-step-1;
    }
    .active {
      // padding-left: $padding-step-1;
      svg {
        padding-right: $padding-step-1;
      }
    }
  }
  .breadcrumbs-custom-path li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    text-transform: capitalize;
    color: #eee;
    list-style-type: none;
    -webkit-appearance: none;
    outline: none;
  }
  .breadcrumbs-custom-path a,
  .breadcrumbs-custom-path a:active {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }
}
