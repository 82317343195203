.download-box{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0px;
    min-height: 100px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}