.customer-content-body {
  margin: $margin-step-2 $margin-step-0;
  .dash {
    display: flex;
    align-items: stretch;
    cursor: pointer;

    &-item {
      width: 100%;
      min-width: 100%;
      background: $white;
      border-radius: 6px;
      margin-bottom: 1.5rem;
      box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
      border: 1px solid rgba(238, 238, 238, 0.75);

      &-body {
        padding: 20px 25px 20px;

        h2 {
          margin-bottom: $margin-step-1;
          color: $blue;
          @include font-Montserrat-semibold;
        }

        .profile-img.img-rounded {
          border-radius: 100%;
        }

        @media (min-width: 1200px) {
          .profile-img.no-avatar {
            font-size: 14px;
          }
        }

        .profile-img.no-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-transform: uppercase;
          font-family: Roboto, sans-serif;
          font-weight: 600;
          font-size: 14px;
        }

        .profile-img.bg-inverse-primary {
          color: #4d8af0;
          background: rgba(77, 138, 240, 0.2);
        }

        .profile-img.bg-inverse-danger {
          color: #ff5f66;
          background: rgba(255, 95, 102, 0.2);
        }

        .profile-img.bg-inverse-warning {
          color: #f0825f;
          background: rgba(240, 130, 95, 0.2);
        }

        .profile-img.bg-inverse-success {
          color: #00e093;
          background: rgba(0, 224, 147, 0.2);
        }

        .profile-img {
          width: 60px;
          height: 60px;
          overflow: hidden;

          svg {
            width: 40px;
          }
        }
      }
    }
  }
}

a {
  .dash-btn {
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    border: 1px solid rgba(238, 238, 238, 0.75);
    border-radius: 6px;
    padding: $padding-step-3;
    background-color: $white;
    text-align: center;
    @include font-Montserrat-regular;
  }
}
