html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

html,
body {
  @include font-Montserrat-medium;
  background: $white;
  color: $black;
  font-size: $font16;
  height: 100%;
  line-height: $line-height-24;
  margin: 0;

  @include bp(md-max) {
    line-height: $line-height-21;
  }
}

h1 {
  font-size: $font38;
  line-height: $line-height-46;
  margin-bottom: $margin-step-3;
  @include bp(md-max) {
    font-size: $font35;
    line-height: $line-height-42;
    margin-bottom: $margin-step-2;
  }
}
h2 {
  font-size: $font30;
  line-height: $line-height-36;
  margin-bottom: $margin-step-3;
  @include bp(md-max) {
    font-size: $font25;
    line-height: $line-height-38;
    margin-bottom: $margin-step-2;
  }
}
h3 {
  font-size: $font22;
  line-height: $line-height-30;
  margin-bottom: $margin-step-3;
  @include bp(md-max) {
    font-size: $font20;
    line-height: $line-height-24;
    margin-bottom: $margin-step-2;
  }
}
h4 {
  font-size: $font18;
  line-height: $line-height-26;
  margin-bottom: $margin-step-2;
  @include bp(md-max) {
    font-size: $font16;
    line-height: $line-height-21;
    margin-bottom: $margin-step-1;
  }
}
h5 {
  font-size: $font14;
  line-height: $line-height-20;
  margin-bottom: $margin-step-1;
  @include bp(md-max) {
    font-size: $font14;
    line-height: $line-height-18;
    margin-bottom: $margin-step-1;
  }
}
p {
  font-size: $font18;
  line-height: $line-height-24;
  margin-top: $margin-step-0;
  margin-bottom: $margin-step-3;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  word-spacing: -0.05em;
  @include bp(md-max) {
    font-size: $font14;
    line-height: $line-height-21;
    margin-bottom: $margin-step-2;
  }
}
a {
  @include font-Montserrat-semibold;
  cursor: pointer;
  color: $blue;
  text-decoration: none;
  &:hover {
    color: $dark-blue;
  }
}

.no-page {
  padding: 100px;
  margin: 100px 0;
  font-weight: 900;
  background-color: $blue;
  color: $white;
  @include bp(md-max) {
    text-align: center;
  }
  p {
    color: $white;
  }
}
.no-page-bottom {
  padding: 0px 100px 100px 100px;
  @include bp(md-max) {
    padding: 0 $padding-step-2 $padding-step-2 $padding-step-2;
  }
  text-align: center;
}
.more-btn-wrapper {
  float: right;
}
.para-long {
  margin-bottom: $margin-step-3;
}

.cta-btn {
  @include bp(md-max) {
    text-align: center;
  }
  .btn-style {
    @include font-Montserrat-semibold;
    padding: 12px 42px 14px;
    color: $white;
    border-radius: 8px;
    outline: none;
    border: 1px solid $blue;
    background: $blue;
    transition: all 1.8s ease-in-out;
    &:hover {
      color: $blue;
      border: 1px solid $blue;
      background-color: $white;
    }
  }
}
.text-right {
  text-align: right;
}

.container {
  width: 100%;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}
.scrollToTop {
  position: fixed;
  z-index: 5454;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  animation: scrollToTopAnimation 2s infinite alternate;
}

@keyframes scrollToTopAnimation {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 50px;
  }
}
.error {
  @include font-Montserrat-regular;
  color: $red;
}
.from-btn {
  margin-top: $margin-step-1;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width to make it slim */
}

::-webkit-scrollbar-thumb {
  background-color: $blue; /* Set the color of the scrollbar thumb */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0e7a89; /* Set the color when hovering over the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the scrollbar track */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.customer-content {
  background-color: $white-op-2;
  margin-top: 60px;
  margin-left: 250px;
  padding-bottom: 60px;
  height: 100vh;
  &-heading {
    padding: $padding-step-1;
    border-bottom: 1px solid $blue-op;
    h3 {
      margin-bottom: 4px;
      margin-top: 6px;
      span {
        @include font-Montserrat-semibold;
        color: $blue;
      }
    }
  }
  &-body {
    // padding: $padding-step-1;
  }
}
.wrapper {
  // display: flex;
}
input {
  &:focus {
    outline-color: $blue !important;
    box-shadow: none !important;
    border: 2px solid $blue;
  }
}
.form-item:has(label.error) {
  input,
  textarea {
    border: 1px solid $red;
  }
}

.card {
  border: 1px solid $blue;
  margin-top: $margin-step-3;
  margin-bottom: $margin-step-10;
  &-head {
    padding: $padding-step-1;
    border-bottom: 1px solid $blue;
    h4 {
      @include font-Montserrat-medium;
      margin-bottom: $margin-step-0;
      display: inline;
    }
    span {
      float: right;
      padding-left: $padding-step-1;
      button {
        padding: 0.25rem 0.5rem !important;
        font-size: 0.875rem;
        border-radius: 0.2rem !important;
      }
    }
  }
  &-body {
    padding: $padding-step-2 $padding-step-1;
    textarea {
      resize: none;
    }
  }
}
form {
  .cta-btn {
    text-align: left;
    .btn-style {
      padding: 8px 34px 10px !important;
    }
  }
}

.br{
  display: block;
  @include bp(md-max){
    display: none;
  }
}