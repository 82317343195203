.bg1 {
  background: url(../../public/image/b1.jpeg) no-repeat top;
  background-size: cover;
}
.banner-wrap {
  height: auto;
  position: relative;
  overflow: hidden;
  .banner-slider {
    height: 100%;
    .banner-slide {
      min-height: 100vh;
      position: relative;
      z-index: 0;
      display: grid;
      align-items: center;
      padding: 3em 0;
      .hero-content {
        color: $white;
      }
      .long-text {
        h1 {
          max-width: 100% !important;
          margin-bottom: 18px;
          color: $white;
          max-width: 700px;
          font-weight: 900;
        }
        p {
          color: $white;
          margin-bottom: $margin-step-0;
          .macro {
            @include font-Montserrat-semibold;
            font-size: 20px;
          }
          .micro {
            @include font-Montserrat-semibold;
            font-size: 17px;
          }
          .nano {
            @include font-Montserrat-semibold;
            font-size: 14px;
          }
        }
      }
      .container {
        position: relative;
        z-index: 12;
      }
    }
  }
}
.hero-overlay {
  background: $overlay;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 76;
  pointer-events: none;
  svg {
    width: 100%;
    path {
      fill: $white;
    }
  }
}
