.profile {
  &-data {
    label {
      width: 300px;
      // padding-right: $padding-step-5;
      margin-bottom: $margin-step-2;
    }
    span {
      // left: 300px;
    }
    &-item {
      display: grid;

      grid-template-columns: auto 1fr;
      grid-gap: 0px;
    }
  }
}
