.nanatom-footer {
  bottom: 0 !important;
  width: 100%;
  height: 200px;
  color: $white;

  &-sub {
    background-color: $footer-dark-blue;
    padding: $padding-step-10;
    padding-bottom: $padding-step-9 + 1;
    @include bp(md-max) {
      padding: $padding-step-2 $padding-step-0;
    }
    &-left {
      display: flex;
      flex-wrap: nowrap;
      align-content: space-around;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 992px) {
        margin-bottom: $margin-step-4;
      }
      &-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        @media screen and (max-width: 992px) {
          margin-bottom: $margin-step-2;
        }
        p {
          color: $white;
          margin-bottom: $margin-step-1;
        }
        .footer-icon {
          height: 60px;
          margin-right: $margin-step-4;
          @include bp(md-max) {
            margin-right: $margin-step-2;
          }
          svg {
            font-size: 40px;
            @include bp(md-max) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  &-copyright {
    background-color: $black-2;
    padding: $padding-step-3 - 4px 0;
    .CopyRights {
      color: #a0a0a0;
      font-size: 15px;
      line-height: 20px;
      margin: $margin-step-0;
      padding: $padding-step-0;
      .designer {
        @include font-Montserrat-medium;
        color: #a0a0a0;
        &:hover {
          @include font-Montserrat-semibold;
          color: $blue;
        }
      }
    }
  }
}
.gmap-iframe {
  // border: 10px solid #f1f3f4;
  border-radius: 12px;
}
.customer-footer {
  margin-left: 250px;
  position: fixed;
  .nanatom-footer-copyright {
    padding: 15px 0;
  }
}