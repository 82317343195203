.ticket-details,
.ticket-conversations {
  border-bottom: 1px solid $blue-op-less;
  padding-bottom: $padding-step-2;
  margin-bottom: $margin-step-1;
}
.ticket-details {
  padding-bottom: $padding-step-0;
  margin-bottom: $margin-step-0;
  &-heading {
    padding-bottom: $padding-step-1;
  }
  p {
    margin-bottom: $margin-step-1 !important;
  }
}
.ticket-conversations {
  &:last-child {
    border-bottom: none;
  }
  .ticket-conversations-details {
    margin-top: $margin-step-2;
    padding-bottom: $padding-step-1;
    &-name {
      @include font-Montserrat-medium;
      color: $blue;
      line-height: 0.5;
    }
    &-date {
      color: $dark-blue;
      font-size: 10px;
    }
  }
}
