.home-section-two {
  background-color: $blue;
  color: $white;
  p {
    color: $white;
  }
  @include bp(md-max) {
    text-align: center;
    .container {
      max-width: unset;
    }
    p {
      text-align: center;
    }
  }
}
.home-image-session {
  padding: 0px !important;
}
.area-box {
  padding: 50px 40px;
  position: relative;
  border-radius: 8px;
  background-color: $blue;
  min-height: 285px;
  color: $white;
}
a.title-head {
  margin-top: 25px;
  display: block;
  color: var(--heading-color);
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 26px;
  text-decoration: none;
}
.accordion-button {
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  color: $black-1;
  background-color: $grey;
}
.accordion-collapse {
  font-weight: 600;
}
button:focus:not(:focus-visible) {
  outline: 0;
  border: none;
  box-shadow: none;
}
.radius-image {
  border-radius: 8px;
}
.home-session-four {
  @media screen and (min-width: 992px) {
    .row {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      flex-wrap: wrap !important;
    }
    .row > [className*="col-"] {
      display: flex !important;
    }
  }
  p {
    color: $white !important;
  }
  .click-here {
    @include font-Montserrat-medium;
    color: $white;
    border-bottom: 2px solid $white;
    &:hover {
      color: $white;
    }
  }
}
.home-section-product {
  h4 {
    @include font-Montserrat-semibold;
  }
  .product-list {
    display: flex;
    padding: $padding-step-1 !important;
    width: 33.33333%;
    &-item {
      padding: $padding-step-2;
      border: 1px solid $blue-op-less;
      background-color: $white;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background-color: #169faf29;
        h4 {
          &:after {
            transform: scaleX(1);
          }
          &:after {
            transform-origin: 0% 50%;
          }
        }
      }
    }
    h4 {
      @include font-Montserrat-semibold;
      display: inline-block;
      &:after {
        display: block;
        content: "";
        border-bottom: solid 3px #019fb6;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
    @include bp(md-max) {
      width: 100%;
    }
  }
}
.home-section-client {
  padding-bottom: 4.5rem !important;
  .title-style {
    @include font-Montserrat-semibold;
  }
  .client-wrapper{
    display: flex;
    justify-content: center;
    @include bp(sm-min){
      width: 100%;
      flex-direction: column;
    }
    @include bp(md-max){
      flex-wrap: wrap;
    }
  }
  .client {
    padding: $padding-step-1 !important;
    width: 20%;
    min-height: 160px;
    // @include bp(md-max){
    //   width: 100%;
    // }
    @include bp(md-max){
      flex-wrap: wrap;
      width: 30%;
    }
    @include bp(sm-min){
      width: 100%;
      flex-direction: column;
    }
    &-item {
      display: flex;
      align-items: center;
      min-height: 160px;
      padding: $padding-step-2;
      border: 1px solid $blue-op-less;
      background-color: $white;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;
      justify-content: center;
      img {
        width: 72%;
      }
    }
  }
  .footer-logos {
    text-align: center;
    padding-top: $padding-step-2;
    padding-bottom: 3.5rem !important;
    img {
      width: 250px;
      &:first-child {
        padding-right: $padding-step-3;
      }
    }
  }
}
.patent-img{
  width: 235px;
  height: 200px;
  margin-left: 10px;
}