.contact-info {
  padding-bottom: $padding-step-2;
  .contact-infos {
    display: grid;
    @include bp(sm-max) {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
    @include bp(md-min) {
      grid-template-columns: 1fr 1fr;
    }
    @include bp(lg-min) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
    }
    .single-contact-infos {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 25px;
      @include bp(sm-max) {
        grid-gap: 15px;
      }
      p {
        margin-bottom: $margin-step-1;
        a {
          @include font-Montserrat-medium;
          color: $black;
        }
      }
    }
  }
  .form-inner-cont {
    textarea {
      resize: none;
      height: 219px;
    }
  }
  &-top {
    &-right {
      @include bp(lg-min) {
        // padding-left: $padding-step-5;
        padding-bottom: $padding-step-2;
      }
      @include bp(md-max) {
        display: flex;
        justify-content: space-between;
      }
      @include bp(sm-min) {
        display: block;
      }
    }
  }
  &-left {
    .order-2 {
      padding-left: $padding-step-5;
      padding-right: $padding-step-5;
    }
  }
  &-right {
    border-left: 1px solid $blue;
    padding-left: $padding-step-5 !important;
    padding-right: $padding-step-5;
    border-bottom: none;
    @include bp(md-max) {
      padding-bottom: $padding-step-2;
      border-bottom: 1px solid $blue;
      border-left: none;
    }
  }
}
.contact-footer {
  height: unset !important;
}
.contact-items {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0px;
  .item {
    h4 {
      @include font-Montserrat-semibold;
      color: $black;
      margin-bottom: $margin-step-1;
    }
    p {
      margin-bottom: $margin-step-0;
    }
    a {
      @include font-Montserrat-medium;
      color: $black;
    }
  }
}
.address,
.email {
  margin-bottom: $margin-step-3;
}
