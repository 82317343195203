.login {
  flex-direction: row !important;
  @include bp(md-max) {
    flex-direction: column-reverse !important;
    padding: 0 $padding-step-2;
  }
  &-form {
    border-left: 1px solid $blue;
    border-bottom: none;
    padding-left: $padding-step-5;
    padding-right: $padding-step-5;
    @include bp(md-max) {
      padding-left: $padding-step-3;
      padding-right: $padding-step-3;
      border-bottom: 1px solid $blue;
      border-left: none;
    }
    .btn-primary {
      color: $white;
      background-color: $blue !important;
      border-color: $blue !important;
      width: 160px;
      height: 50px;
      @include bp(md-max) {
        width: 110px;
        height: 40px;
      }
    }
    .button-section {
      position: relative;
      .register-link {
        position: absolute;
        right: 0;
        display: inline-block;
        p {
          margin-bottom: $margin-step-0;
        }
      }
    }
  }
  .register-link {
    p {
      margin-bottom: $margin-step-2;
      a {
        @include font-Montserrat-medium;
      }
    }
  }
  &-left {
    padding-left: $padding-step-5;
    padding-right: $padding-step-5;
    @include bp(md-max) {
      padding-left: $padding-step-3;
      padding-right: $padding-step-3;
    }
  }
}
.password-example {
  border: 1px solid $blue;
  h4 {
    @include font-Montserrat-semibold;
    color: $blue;
  }
  .password {
    @include font-Montserrat-medium;
    color: $blue;
  }
}
.OTP {
  .optContainer {
    justify-content: center;
    margin: $margin-step-10 $margin-step-0;
    span {
      color: $blue-op;
      padding: $padding-step-0 $padding-step-1;
      @include bp(md-max) {
        padding: $padding-step-0 $padding-step-0 + 2;
      }
    }
    .otpInputBox {
      width: 3rem !important;
      height: 3rem !important;
      border-radius: 4px;
      border: 1px solid $blue-op;
      @include bp(md-max) {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }
  .button-section {
    .btn {
      width: 130px !important;
      height: 40px !important;
      @include bp(md-max) {
        font-size: 12px;
        width: 100px !important;
        height: 30px !important;
      }
    }
  }
}
