aside {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 250px;
  // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  background-color: $white;

  height: 100vh;
  overflow-y: hidden;
  z-index: 1038;

  .brand-link {
    padding: $padding-step-1;
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.8125rem 0.5rem;
    transition: width 0.3s ease-in-out;
    white-space: nowrap;
    border-bottom: 1px solid $grey;
    height: 60px;
  }
  .brand-image {
    height: 30px;
  }
  .sidebar {
    height: calc(100vh - (3.5rem + 1px));

    padding: 0;
    &_inner {
      .nav-item:has(a.active) {
        background-color: $blue;
      }
      .nav-item {
        padding: $padding-step-1 0;
        border-bottom: 1px solid $grey;

        .active {
          color: $white !important;
          background-color: $blue !important;
        }
        svg {
          max-width: 30px;
          width: 20px;
        }
        a {
          @include font-Montserrat-regular;
          color: $black;
          padding: 0 $padding-step-1;
          p {
            display: inline-block;
            margin-bottom: $margin-step-1;
            padding-left: $padding-step-1;
          }
        }
        &:last-child {
          bottom: 0;
          position: absolute;
          width: 100%;
          border-top: 1px solid $grey;
        }
        &:hover {
          background-color: $grey;
          a {
            color: $blue;
          }
        }
      }
    }
  }
}
