.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
  align-items: center;
  justify-content: center;
  &__content {
    color: $white;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__spinner {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    border: 4px solid $white;
    border-top: 4px solid $blue;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
