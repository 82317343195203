.products {
  .filter {
    background-color: $white-op-0;
    // color: $white;
    padding: $padding-step-1;
    border-radius: 8px;
    @include bp(md-max) {
      margin-bottom: $margin-step-1;
    }
    &-heading {
      padding: $padding-step-1 $padding-step-1 $padding-step-0 $padding-step-0;
      border-bottom: 4px solid $blue;
      h3 {
        margin-bottom: $margin-step-0;
      }
    }

    &-items {
      padding-top: $padding-step-1;
      &-item {
        cursor: pointer;
        border-bottom: 1px solid $blue-op;
        display: block;
        padding: $padding-step-1 $padding-step-0 $padding-step-1 $padding-step-1;
        &:last-child {
          border-bottom: none;
        }
        i{
          padding-left: $padding-step-1;
          font-size: 12px;
        }
      }
      .activeFilter {
        background-color: $blue;
        color: $white;
      }
    }
  }
  .products-list {
    background-color: $white-op-0;
    border-radius: 8px;
    // color: $white;
    .result-count {
      padding: $padding-step-1 $padding-step-0;
    }
    .pagination {
      text-align: center;
      display: flex;
      justify-content: center;
      padding: $padding-step-2;
    }
    .product-list {
      &-item {
        padding: $padding-step-1;
        border: 1px solid $blue;
        border-radius: 8px;
        margin-bottom: $margin-step-2;
        background-color: $white;
        max-height: 415px;
        height: 100%;
        &-img {
          padding-bottom: $padding-step-1;
          img {
            max-width: 100%;
            height: 200px;
            border-radius: 8px;
            box-shadow: 0px 0px 3px $white;
          }
        }
        // &:hover{
        //   background-color: $blue;
        //     transition-delay: 0.25s;
        //     color: $white;
        //     transition-timing-function: linear;
        //   .btn{
        //     background-color: $white;
        //     color: $blue;
        //     transition-delay: 0.25s;
        //   }
        // }
        &-detail {
          padding: $padding-step-1 $padding-step-0;
          height: 190px;
          position: relative;
          h3 {
            @include font-Montserrat-semibold;
            margin-bottom: $margin-step-0;
            text-transform: capitalize;
            color: $blue;
          }
          span {
            @include font-Montserrat-regular;
            text-transform: uppercase;
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
          &-btn {
            margin: $margin-step-1 $margin-step-0;
            position: absolute;
            width: 100%;
            bottom: 0;
            .btn {
              width: 100%;
              background-color: $blue;
              border-color: $blue;
            }
          }
        }
      }
    }
  }
}

.navigationButtons {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: auto;
  margin: $margin-step-2 $margin-step-0;
  a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    color: $blue !important;
    cursor: pointer;
    &:hover {
      color: $white !important;
      background-color: $blue;
    }
  }
}

.navigationActive {
  a {
    color: $white !important;
    background-color: $blue;
  }
}
.navigationDisabled,
.pagination-hidden,
.navigationDisabled {
  a {
    display: none;
  }
  display: none;
}
.productDetail {
  padding-top: $padding-step-5;
  &-header {
    margin-bottom: $margin-step-3;
    h1,
    p {
      margin-bottom: $margin-step-1;
    }
  }

  &-body {
    padding-bottom: $padding-step-5;
    &-links {
      padding-bottom: $padding-step-1;
      border-bottom: 4px solid $blue-op;

      .p-link {
        margin-right: $margin-step-1;
        border: 1px solid $blue-op !important;
        border-radius: 4px;
        color: $blue;
        transition: all 1.8s ease-in-out;
        &:hover {
          color: $white;
          border: 1px solid $blue;
          background-color: $blue;
        }
      }
      .active {
        background-color: $blue !important;
        color: $white !important;
      }
    }
    &-item {
      width: 100%;
      &-header {
        padding-top: $padding-step-2;
        h2 {
          margin-bottom: $padding-step-2;
        }
      }
      &-body {
        padding-bottom: $padding-step-2;
        width: 100%;
        .overview-img {
          width: 100%;
          height: auto;
          max-height: 50rem;
          border-radius: 8px;
        }
        .specifications-table {
          border: 1px solid $blue;
          width: 100%;
          @include bp(md-max) {
            margin-bottom: $margin-step-1;
          }
          tr {
            border-bottom: 1px solid $blue-op;
            th,
            td {
              padding: $padding-step-1;
            }
            th {
              border-right: 1px solid $blue;
              @include bp(md-max) {
                width: 50%;
              }
            }
          }
        }
      }
      .specifications {
        display: flex;
        justify-content: space-between;
      }
      &-right {
        margin-top: $margin-step-3;
        background-color: $blue-op-less;
        padding: $padding-step-1;
        border-radius: 8px;
      }
    }
  }
}
.right-action-btn {
  top: 50%;
  background-color: $blue;
  color: $white;
  display: inline-block;
  font-size: 1rem;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 0;
  border: 1px solid $blue;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  width: 150px;
  animation: rightActionButtonAnimation 2s infinite alternate;

  cursor: pointer;
  a {
    @include font-Montserrat-medium;
    color: $white;
    text-decoration: none;
    padding: $padding-step-1;
  }
}

@keyframes rightActionButtonAnimation {
  0% {
    background-color: $blue;
    color: $white;
    top: 50%;
  }
  100% {
    background-color: $blue-op;
    color: $blue;
    top: 45%;
  }
}
