.navbar {
  background-color: $white !important;
  height: 60px;
  @include bp(md-max) {
    background-color: transparent !important;
  }
  &-brand {
    @include bp(md-max) {
      // background-color: $white-op;
      padding: 10px;
      border-radius: 5px;
    }
  }
  &-logo {
    height: 30px;
  }
  &-collapse {
    justify-content: right;
    @include bp(md-max) {
      background-color: $white !important;
      float: left;
      text-align: center;
      width: 100%;
      padding: 20px;
      margin-top: 16px;
      box-shadow: 0 6px 19px rgba(0, 0, 0, 0.05);
    }
  }
  &-nav {
    .active {
      color: $blue !important;
    }
  }
  .nav-item {
    color: $black-1;
    font-weight: 600;
    &:hover {
      a {
        color: $blue !important;
      }
    }
    @media screen and (max-width: 992px) {
      padding: 7px 0;
      text-align: center;
      .nav-link {
        padding: 0;
        font-size: 16px;
        color: $black-2;
        font-weight: 700;
      }
      .active {
        color: $blue !important;
      }
    }
  }
  .navbar-toggler {
    height: 40px;
    width: 40px;
    padding: 0;
    appearance: none;
    // border: 3px solid #169faf;
    // background: #169faf !important;
    .fa-bars:before {
      content: "\f0c9";
    }
    .fa-times:before {
      content: "\f00d";
    }
    &-icon {
      background-image: none;
    }
  }

  @media screen and (max-width: 992px) {
  }
}
@media only screen and (max-width: 991px) {
  .navbar {
    height: auto;
    padding: 5px 0;
  }
  .active {
    background: transparent !important;

    .navbar-collapse {
      background-color: $white;
      margin-top: $margin-step-5;
      padding: $padding-step-5;
      border-radius: 10px;
      ul {
        li {
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}
@include bp(md-max) {
  .nav-fixed {
    height: 60px;
    // padding: 14px 0;
  }
  .nav-fixed {
    transition: all 0.5s ease-in-out;
    padding: 0;
    background-color: $white;
    height: 60px;
    border-bottom: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    z-index: 99 !important;
  }
}
.nav-fixed {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  z-index: 99 !important;
}
.customer-header{
  border-bottom:  1px solid $grey;
}